import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, useLocation } from 'react-router-dom';
import { CacheProvider } from '@emotion/react'; // Import CacheProvider from Emotion
import createCache from '@emotion/cache'; // Import createCache from Emotion
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import { AuthProvider } from './context/AuthContext';
import { ThemeProvider } from './context/ThemeContext';

// Define route to title mapping
const routeTitleMap = {
  '/': 'Home - FunAITutor',
  '/login': 'Login - FunAITutor',
  '/register': 'Register - FunAITutor',
  '/change-password': 'Change Password - FunAITutor',
  '/forgot-password': 'Forgot Password - FunAITutor',
  '/verify-email': 'Verify Email - FunAITutor',
  '/parent-dashboard': 'Parent Dashboard - FunAITutor',
  '/chatbot': 'Chatbot - FunAITutor',
};

// Function to track page views using GTM
const trackPageViewWithGTM = (path, title) => {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: 'page_view',
      page_path: path,
      page_title: title,
    });
  }
};

// Component to track page views and update titles on route changes
const TrackPageView = () => {
  const location = useLocation();

  useEffect(() => {
    const title = routeTitleMap[location.pathname] || 'FunAITutor';
    document.title = title;

    // Ensure dataLayer exists
    if (!window.dataLayer) {
      window.dataLayer = [];
    }

    // Track page view
    trackPageViewWithGTM(location.pathname, title);
  }, [location]);

  return null;
};

// Get the nonce from the server-rendered <meta> tag
const styleNonce = document.querySelector('meta[name="style-nonce"]')?.getAttribute('content');
if (!styleNonce) console.error('Style nonce not found!'); // Debug line

// Create an Emotion cache with the nonce
const cache = createCache({
  key: 'css', // Cache key, can be any string
  nonce: styleNonce, // Pass the correct style nonce to Emotion
  speedy: process.env.NODE_ENV === 'production',
});

// Root rendering logic
const root = document.getElementById('root');
const appRoot = createRoot(root);

appRoot.render(
  <React.StrictMode>
    <CacheProvider value={cache}> {/* Wrap the app in CacheProvider */}
      <BrowserRouter>
        <ThemeProvider>
          <AuthProvider>
            <TrackPageView />
            <App />
          </AuthProvider>
        </ThemeProvider>
      </BrowserRouter>
    </CacheProvider>
  </React.StrictMode>
);

reportWebVitals(console.log);
